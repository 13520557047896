@import "@Static/css/common";
.root {
    width: 100vw;
    height: 100vh;
    background-color: #f6fafc;
    color: #2e384d;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
}

.bodyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.boxContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid rgba(162, 162, 162, 0.4);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    width: 35%;
    padding: 1.5rem 0;
}

.innerbox {
    padding: 0 1.5rem;
}

.image {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000014;
}

.loginDescription {
    margin: 1rem 0;
}

.inputLabel {
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 4px;
    color: #000000;
    font-weight: 500;
}

.inputField {
    width: 100%;
}

.inputError {
    color: red;
    font-size: 0.7rem;
    min-height: 20px;
}

.disclaimer {
    font-size: 0.7rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    color: #7a869a;
    border-top: 1px solid rgba(162, 162, 162, 0.4);
}

.link {
    font-size: 0.7rem;
    color: #166cd1 !important;
    text-decoration: none !important;
    &,
    &:hover {
        @include colorCombinationXYZ(
            (
                "tc": (
                    "key": 1,
                    color: (
                        0,
                        0,
                        0,
                    ),
                ),
            )
        );
        text-decoration: underline;
    }
}

.submitButton {
    width: 100%;
    background-color: #000000;
    border-radius: 6px;
    padding: 7px 27px;
    color: rgb(
        var(--template-primary-button-text-color-host-theme, 255, 255, 255)
    );
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
                color: (
                    0,
                    0,
                    0,
                ),
            ),
        )
    );
}

.flexRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.errorMsg {
    font-size: 0.7rem;
    margin-bottom: 4px;
    color: #000000;
    font-weight: 400;
    margin-left: 1.5rem;
}
.errorMsg span {
    color: #ea661d;
}

.googleLoginLabel {
    font-size: 0.85rem;
    margin: 1rem 0;
    color: #000000;
    font-weight: 300;
    text-align: center;
}

.hrLines:before {
    content: " ";
    display: block;
    height: 0.01rem;
    width: 30%;
    position: absolute;
    top: 50%;
    left: 0;
    background: #000000;
}

.hrLines:after {
    content: " ";
    height: 0.01rem;
    width: 30%;
    background: #000000;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}

.hrLines {
    position: relative;
    max-width: 100%;
    margin: 1rem 1rem;
    text-align: center;
}

.googleBtn {
    width: 100%;
    border: 0.02rem solid #000000;
    color: #000000;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 8px 10px;
    font-size: 0.8rem;
    font-weight: 300;
    text-decoration: none;
}

.googleBtn div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.googleIcon {
    height: 1.5rem;
    object-fit: contain;
    margin-right: 0.5rem;
}

.timezoneDiv {
    margin: 0px 0px 10px;
}

.globeIcon {
    height: 20px;
    object-fit: contain;
    margin-right: 8px;
}

@media only screen and (max-width: 768px) {
    .boxContainer {
        width: 93%;
        padding: 1.5rem 0;
    }
    .innerbox {
        padding: 0 1rem;
    }
    .loginDescription {
        font-size: 13px;
    }
    .disclaimer {
        font-size: 0.6rem;
    }
    .link {
        font-size: 0.6rem;
    }
}
