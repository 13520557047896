@import "@Static/css/common";

.root {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: -2px 0 12px;

    .icon {
        font-size: 20px;
        @include colorCombinationXYZ(
            (
                "tc": (
                    "key": 1,
                ),
            ),
            "schema1"
        );
    }
    .dropdown {
        min-width: min(200px, 50vw);
        width: 100%;
        max-width: 280px;
        div[class$="control"] {
            background-color: transparent;
            line-height: 20px;
            min-height: 26px;
            border-width: 1px;
            border-style: solid;
            border-color: transparent;
            cursor: pointer;
            &:hover {
                background-color: #fcfcfc44;
                border-color: #ddd;
            }
        }
        div[class$="option"] {
            font-size: 14px;
        }
        div[class$="ValueContainer"] {
            padding: 2px 0 2px 4px;
        }
        div[class$="singleValue"] {
            color: inherit;
            font-size: 13px;
            font-weight: 600;
            height: min-content;
        }
        div[class$="indicatorContainer"] {
            padding-top: 0;
            padding-bottom: 0;
        }
        div[class$="Input"] {
            font-size: 13px;
            padding-top: 0;
            padding-bottom: 0;
            height: min-content;
        }
        &.selected {
            div[class$="control"] {
                @include colorCombinationXYZ(
                    (
                        "tc": (
                            "key": 3,
                        ),
                    ),
                    "schema1"
                );
            }
            div[class$="placeholder"] {
                font-size: 12px;
                white-space: pre;
                letter-spacing: -0.3px;
                @include colorCombinationXYZ(
                    (
                        "tc": (
                            "key": 1,
                        ),
                    ),
                    "schema1"
                );
            }
        }
    }
}
